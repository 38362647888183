/**
 * omnivision theme custom JS main file
 */
jQuery(document).ready(function () {
  // $('#same-every-day').on('change', function() {
  //     if ($(this).is(':checked')) {
  //         // Hide individual day inputs
  //         $('.arming-times .d-flex-main').hide();

  //         // Create and show a single set of arm/disarm inputs
  //         var singleTimeInputHtml = `
  //             <div class="d-flex" style="margin-bottom:10px">
  //                   <small style="    min-width: 163px;">Disarm</small>
  //                   <small>Arm</small>
  //             </div>
  //             <div id="single-time-input" class="mb-1 d-flex-main">
  //                 <div class="d-flex">
  //                     <input type="time" id="single-disarming-time" class="form-control" placeholder="Disarm" step="900">
  //                     <input type="time" id="single-arming-time" class="form-control" placeholder="Arm" step="900">
  //                 </div>
  //             </div>`;
  //         $('.arming-times').append(singleTimeInputHtml);

  //         // Optional: Populate the single set of inputs with values from one of the days
  //         $('#single-disarming-time').val($('#disarming-time-monday').val());
  //         $('#single-arming-time').val($('#arming-time-monday').val());
  //         $('#single-disarming-time').step = "10";

  //         // Add listener to update hidden fields
  //         $('#single-disarming-time, #single-arming-time').on('change', function() {
  //             var singleDisarmTime = $('#single-disarming-time').val();
  //             var singleArmTime = $('#single-arming-time').val();
  //             $('.arming-times input[type="time"]').val(function() {
  //                 return this.id.includes('disarming') ? singleDisarmTime : singleArmTime;
  //             });
  //         });
  //     } else {
  //         // Show individual day inputs
  //         $('.arming-times .d-flex-main').show();

  //         // Remove the single set of arm/disarm inputs
  //         $('#single-time-input').remove();
  //     }
  // });




  if ($(".reviews").length) {
    $(".reviews").slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false, // Hide default arrows
      dots: false, // Hide dots
    });
    // Custom arrows
    $(".arrows .left").click(function () {
      $(".reviews").slick("slickPrev");
    });

    $(".arrows .right").click(function () {
      $(".reviews").slick("slickNext");
    });
  }

  $("#burgerMenu").click(function (event) {
    event.preventDefault();
    $(".mobile_menu, .fullscreen_black_div").fadeToggle(); // Toggle the visibility of the menu and the overlay
  });

  $(".fullscreen_black_div").click(function () {
    $(".mobile_menu, .fullscreen_black_div").fadeOut(); // Hide the menu and overlay when the overlay is clicked
  });

  $("#password-lost-form-wrap").hide();
  $(".forgot").click(function () {
    $("#password-lost-form-wrap").show();
    $("#loginform").hide();
    $(".forgot").hide();
    $(".init").hide();
    $(".signin").show();
    $(".return").show();
  });
  if ($(".return").length) {
    $(".return").hide();
  }
  $(".return").click(function () {
    $("#password-lost-form-wrap").hide();
    $(".signin").hide();
    $("#loginform").show();
    $(".forgot").show();
    $(".init").show();
    $(".return").hide();
  });
  $("#user_pass").click(function () {
    togglePassvisibility();
  });

  function togglePassvisibility() {
    var x = $("input[type='password']");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  $(".registration-form input[type='password']").click(function () {
    var input = $(this);
    if (input.attr("type") === "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  $("#registerSubmit").on("click", function (event) {
    event.preventDefault(); // Prevent the form from submitting initially

    var isValid = true;
    var errorMessage = "";

    $("form")
      .find("input[required], select[required]")
      .each(function () {
        if (!$.trim($(this).val())) {
          // The input is empty or only contains whitespace
          $(this).css("border", "1px solid red"); // Highlight the border in red
          isValid = false;
        } else {
          $(this).css("border", ""); // Reset the border if it's filled
        }
      });

    // Password match validation
    var password = $("#password").val();
    var confirmPassword = $("#confirm-password").val();
    if (password !== confirmPassword) {
      $("#password, #confirm-password").css("border", "1px solid red");
      errorMessage += "Passwords do not match.\n";
      isValid = false;
    } else {
      $("#password, #confirm-password").css("border", "");
    }

    // Email match validation
    var email = $("#email").val();
    var confirmEmail = $("#confirm-email").val();
    if (email !== confirmEmail) {
      $("#email, #confirm-email").css("border", "1px solid red");
      errorMessage += "Email addresses do not match.\n";
      isValid = false;
    } else {
      $("#email, #confirm-email").css("border", "");
    }

    if (isValid) {
      // Gather the form data.
      var formData = {
        first_name: $("#first-name").val(),
        last_name: $("#last-name").val(),
        email_address: $("#email").val(),
        contact_phone_number: $("#contact-no").val(),
        company_position: $("#company-position").val(),
        company_name: $("#company-name").val(),
        company_type: $("#company-type").val(),
        password: $("#password").val(),
        agree_privacy: $("#privacy-policy").is(":checked"),
        coupon: $("#coupon").val(),
      };

      console.log(formData);

      // Make the AJAX request.
      $.ajax({
        url: "/wp-json/omnivision/v1/register/",
        method: "POST",
        data: formData,
        success: function (response) {
          console.log("Server responded with:", response);
          var newURL = "/continue-registration"; // Set the redirection URL
          window.location.href = newURL;
        },
        error: function (xhr) {
          // Parse the JSON error object and alert the message
          var errorObj = JSON.parse(xhr.responseText);
          if (errorObj && errorObj.message) {
            alert("Error: " + errorObj.message);
          } else {
            alert("Registration failed. Please try again.");
          }
          console.error("Registration failed:", xhr.responseText);
        },
      });
    } else {
      // Optionally, alert the user or display a message that the form contains errors
      alert(errorMessage || "Please fill out all required fields.");
    }
  });

  if ($("#liability-documentation").length) {
    $("#liability-documentation").on("change", function () {
      var fileName = $(this).val().split("\\").pop(); // Extracts file name from the path
      $(".file-upload-text").text(fileName);
    });
    if ($(".file-upload-wrapper").length) {
      document
        .querySelector(".file-upload-wrapper")
        .addEventListener("click", function () {
          document.getElementById("liability-documentation").click();
        });
    }
  }

  /**
   * Continue
   */
  $("#continueSubmit").on("click", function (event) {
    event.preventDefault(); // Prevent the form from submitting initially

    var isValid = true;
    var errorMessage = "";

    $("form")
      .find("input[required], select[required]")
      .each(function () {
        if (!$.trim($(this).val())) {
          // The input is empty or only contains whitespace
          $(this).css("border", "1px solid red"); // Highlight the border in red
          isValid = false;
        } else {
          $(this).css("border", ""); // Reset the border if it's filled
        }
      });

    // Email match validation
    var email = $("#incident-email").val();
    var confirmEmail = $("#confirm-incident-email").val();
    if (email !== confirmEmail) {
      $("#incident-email, #confirm-incident-email").css(
        "border",
        "1px solid red"
      );
      errorMessage += "Email addresses do not match.\n";
      isValid = false;
    } else {
      $("#email, #confirm-email").css("border", "");
    }

    if (isValid) {
      // Get the form element
      var form = $(".registration-form form")[0];

      // Create a FormData object, which includes all the form fields and their values
      var formData = new FormData(form);

      // Make the AJAX request.
      $.ajax({
        url: "/wp-json/omnivision/v1/continue-registration/",
        method: "POST",
        data: formData,
        contentType: false, // Required for 'multipart/form-data' forms that include file uploads
        processData: false, // Required for 'multipart/form-data' forms that include file uploads
        cache: false,
        success: function (response) {
          console.log("Server responded with:", response);
          var newURL = "/account"; // Set the redirection URL
          window.location.href = newURL;
        },
        error: function (xhr) {
          // Parse the JSON error object and alert the message
          var errorObj = JSON.parse(xhr.responseText);
          if (errorObj && errorObj.message) {
            alert("Error: " + errorObj.message);
          } else {
            alert("Registration failed. Please try again.");
          }
          console.error("Registration failed:", xhr.responseText);
        },
      });
    } else {
      // Optionally, alert the user or display a message that the form contains errors
      alert(errorMessage || "Please fill out all required fields.");
    }
  });
});
jQuery(document).ready(function ($) {
  // Function to toggle the visibility of the incident section
  function toggleIncidentSection() {
    if ($("#same-as-account-holder").is(":checked")) {
      $(".incident_section").hide();
    } else {
      $(".incident_section").show();
    }
  }

  // Run the toggle function on document ready to handle the default state
  toggleIncidentSection();

  // Attach an event listener to the checkbox to handle changes
  $("#same-as-account-holder").change(toggleIncidentSection);
});
jQuery(document).ready(function ($) {
  // Function to toggle the visibility of the incident section
  function toggleInvoiceSection() {
    if ($("#same-as-account-holder-invoice").is(":checked")) {
      $(".invoice_section").hide();
    } else {
      $(".invoice_section").show();
    }
  }

  // Run the toggle function on document ready to handle the default state
  toggleInvoiceSection();

  // Attach an event listener to the checkbox to handle changes
  $("#same-as-account-holder-invoice").change(toggleInvoiceSection);
});

jQuery(document).ready(function ($) {
  $("#password-reset-link").click(function (e) {
    e.preventDefault(); // Prevent the default action
    var userLogin = $(this).data("user-login"); // Get the user login from the data attribute

    // Send the AJAX request
    $.ajax({
      url: "/wp-admin/admin-ajax.php",
      type: "POST",
      data: {
        action: "trigger_password_reset",
        user_login: userLogin,
      },
      success: function (response) {
        //console.log(response);
        alert(response.data.message); // Alert the response message
      },
    });
  });
});

jQuery(document).ready(function ($) {
  $("#account-details-form").submit(function (event) {
    event.preventDefault(); // Prevent the default form submission

    var form = this;
    var formData = new FormData(form); // Create a FormData object

    $.ajax({
      url: "/wp-json/omnivision/v1/edit-account/account", // Your REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        // Redirect to /account/account-details on success
        window.location.href = "/account/account-details";
      },
      error: function (response) {
        // Handle error - display error message, etc.
        alert("An error occurred.");
      },
    });
  });
});

jQuery(document).ready(function ($) {
  $("#edit-company-details").submit(function (event) {
    event.preventDefault(); // Prevent the default form submission

    var form = this;
    var formData = new FormData(form); // Create a FormData object

    $.ajax({
      url: "/wp-json/omnivision/v1/edit-account/company", // Your REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        // Redirect to /account/account-details on success
        window.location.href = "/account/account-details";
      },
      error: function (response) {
        // Handle error - display error message, etc.
        alert("An error occurred.");
      },
    });
  });
});

jQuery(document).ready(function ($) {
  $("#edit-invoice-details").submit(function (event) {
    event.preventDefault(); // Prevent the default form submission

    var form = this;
    var formData = new FormData(form); // Create a FormData object

    $.ajax({
      url: "/wp-json/omnivision/v1/edit-account/invoice", // Your REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        // Redirect to /account/account-details on success
        window.location.href = "/account/account-details";
      },
      error: function (response) {
        // Handle error - display error message, etc.
        alert("An error occurred.");
      },
    });
  });
});

jQuery(document).ready(function ($) {
  $("#add-incident-details").submit(function (event) {
    event.preventDefault(); // Prevent the default form submission

    var form = this;
    var formData = new FormData(form); // Create a FormData object

    $.ajax({
      url: "/wp-json/omnivision/v1/edit-account/incident", // Your REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        // Redirect to /account/account-details on success
        window.location.href = "/account/account-details";
      },
      error: function (jqXHR) {
        // Handle error - display error message, etc.
        var errorMsg = "An error occurred.";
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          errorMsg = jqXHR.responseJSON.message;
        }
        alert(errorMsg);
      },
    });
  });
});

jQuery(document).ready(function ($) {
  $(".deleteRow").on("click", function (event) {
    event.preventDefault();

    var email = $(this).data("email");
    var user = $(this).data("user");

    if (confirm("Are you sure you want to delete this item?")) {
      $.ajax({
        url: "/wp-admin/admin-ajax.php",
        method: "POST",
        data: {
          action: "remove_users_row",
          email: email,
          user_id: user,
        },
        success: function (response) {
          if (response.success) {
            alert("Row deleted successfully");
            location.reload(); // Reload the page to update the view
          } else {
            alert(response.data.message); // Show server-side error message
          }
        },
        error: function () {
          alert("An error occurred while deleting the row.");
        },
      });
    }
  });
});

jQuery(document).ready(function ($) {
  $("#add-site-one").submit(function (event) {
    event.preventDefault(); // Prevent the default form submission

    var contacts = [];
    $("#contact-fields .contact-set").each(function (index) {
      // The 'index' argument correctly represents the index of each 'contact-set'
      var suffix = index + 1;
      var contact = {
        first_name: $(this)
          .find(`input[name="incident_contact_first_name_${suffix}"]`)
          .val(),
        last_name: $(this)
          .find(`input[name="incident_contact_last_name_${suffix}"]`)
          .val(),
        email: $(this)
          .find(`input[name="incident_contact_email_${suffix}"]`)
          .val(),
        contact_no: $(this)
          .find(`input[name="incident_contact_contact_no_${suffix}"]`)
          .val(),
      };
      contacts.push(contact);
    });

    // Assign merged data to hidden input
    $("#incident_contact_data").val(JSON.stringify(contacts));

    var form = this;
    var formData = new FormData(form); // Create a FormData object

    $.ajax({
      url: "/wp-json/omnivision/v1/sites/add/1", // Sites REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        console.log("Site " + response.post_id);
        // Redirect to /account/account-details on success
        window.location.href =
          "/account/my-sites/add-site/step-2?site=" + response.post_id;
      },
      error: function (jqXHR) {
        // Handle error - display error message, etc.
        var errorMsg = "An error occurred.";
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          errorMsg = jqXHR.responseJSON.message;
        }
        alert(errorMsg);
      },
    });
  });
});

$(document).ready(function () {
  if ($("#add-site-two").length) {
    var camerasDiv = document.querySelector(".cameras");
    var hiddenInput = document.getElementById("cameras_to_monitor");

    // Function to create camera input sets
    function createCameraInputSet(index) {
      var rowDiv = document.createElement("div");
      rowDiv.className = "row inputs_row";
      rowDiv.id = "camera_input_set_" + index;
      rowDiv.innerHTML = `
            <div class="col-2">
                <label class="form-label">Camera ${index}</label>
            </div>
            <div class="col-2 tick">
                <input class="form-check-input" type="checkbox" id="camera-monitored-${index}">
            </div>
            <div class="col-2 tick">
                <input class="form-check-input" type="checkbox" id="camera-view-only-${index}">
            </div>
            <div class="col-6">
                <input type="text" class="form-control" id="camera-name-${index}" placeholder="e.g Back Gate">
            </div>
        `;
      camerasDiv.appendChild(rowDiv);
    }

    // Assume the user can enter the number of cameras
    var numberOfCameras = $("#no_cameras").val(); // Replace this with dynamic value as needed
    for (var i = 1; i <= numberOfCameras; i++) {
      createCameraInputSet(i);
    }
  }

  function updateSchedule() {
    var schedule = {};

    // Add public holidays if checked
    if ($("#public-holidays").is(":checked")) {
      schedule["public_holidays"] = "on";
    }

    // Add public holidays if checked
    if ($("#same-every-day").is(":checked")) {
      ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach(function (day) {
        var armTime = $("#single-arming-time").val(); // Assuming you meant to use .val() here
        var disarmTime = $("#single-disarming-time").val(); // Assuming you meant to use .val() here
        schedule[day] = {
          arm: armTime,
          disarm: disarmTime,
        };
      });
    } else if ($("#same-weekdays").is(":checked")) {
      ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach(function (day) {
        var armTimeId = day === "saturday" ? "#arming-time-saturday" : day === "sunday" ? "#arming-time-sunday" : "#weekdays-arming-time";
        var disarmTimeId = day === "saturday" ? "#disarming-time-saturday" : day === "sunday" ? "#disarming-time-sunday" : "#weekdays-disarming-time";

        var armTime = $(armTimeId).val();
        var disarmTime = $(disarmTimeId).val();

        schedule[day] = {
          arm: armTime,
          disarm: disarmTime,
        };
      });
    } else {
      ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].forEach(function (day) {
        var armTime = $("#arming-time-" + day).val();
        var disarmTime = $("#disarming-time-" + day).val();
        schedule[day] = {
          arm: armTime,
          disarm: disarmTime,
        };
      });
    }

    // Set the value of the hidden input
    $("#arming_schedule").val(JSON.stringify(schedule));
    console.log(JSON.stringify(schedule))
    console.log(schedule)

  }

  $("#add-site-two").on("submit", function (e) {
    e.preventDefault(); // Prevent the default form submission

    updateSchedule();

    /**
     * Cameras
     */
    var camerasData = [];
    var numberOfCameras = $("#no_cameras").val();
    for (var i = 1; i <= numberOfCameras; i++) {
      var camera = {
        monitored: $("#camera-monitored-" + i).is(":checked"),
        view_only: $("#camera-view-only-" + i).is(":checked"),
        name: $("#camera-name-" + i).val(),
      };
      camerasData.push(camera);
    }

    // You can then use camerasData as needed, for example, setting it as a JSON string in a hidden input
    $("#cameras_to_monitor").val(JSON.stringify(camerasData));

    /** cameras end */

    var form = $("#add-site-two")[0]; // Get the native form element
    var formData = new FormData(form); // Create a FormData object
    console.log(formData);

    $.ajax({
      url: "/wp-json/omnivision/v1/sites/add/2", // Sites REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        console.log("Site " + response.post_id);
        // Redirect to /account/account-details on success
        window.location.href =
          "/account/my-sites/add-site/step-3?site=" + response.post_id;
      },
      error: function (jqXHR) {
        console.log(jqXHR);
        // Handle error - display error message, etc.
        var errorMsg = "An error occurred.";
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          errorMsg = jqXHR.responseJSON.message;
        }
        alert(errorMsg);
      },
    });
  });
});

$(document).ready(function () {
  function validate_missing_inputs() {
    console.log("testing");

    // Assume no validation errors initially
    var validationFailed = false;

    // Remove existing error class from inputs
    $(".input_error").removeClass("input_error");

    // Check each required input in the form
    $("form")
      .find("input[required], textarea[required], select[required]")
      .each(function () {
        console.log($(this).attr("class"));
        if (!$(this).val()) {
          // If a required field is empty, add 'input_error' class and set validation flag
          $(this).addClass("input_error");
          validationFailed = true;
        }
      });

    // Check file inputs separately as they don't use val()
    $("form")
      .find('input[type="file"][required]')
      .each(function () {
        if (!$(this).get(0).files.length) {
          // If a required file input has no file selected, add 'error' class to its wrapper and set validation flag
          $(this).parents(".file-upload-wrapper").addClass("input_error");
          validationFailed = true;
        }
      });

    // If there was a validation error, prevent the form from submitting
    if (validationFailed) {
      alert("Please fill out all required fields.");
    }
  }
  $(".sumbit_button button").click(function () {
    validate_missing_inputs();
  });

  // Validate that the two dates match on form submission
  $("#add-site-three").submit(function (e) {
    e.preventDefault();

    var startDate = $("#start_date").val();
    var confirmStartDate = $("#confirm_start_date").val();
    var today = new Date();
    var minDate = new Date();
    minDate.setDate(today.getDate() + 3); // set the date to three days from now

    // Format the minimum date as a string (yyyy-mm-dd) to match input value format
    var minDateString = minDate.toISOString().split("T")[0];

    if (startDate !== confirmStartDate) {
      alert(
        "The start dates do not match. Please confirm the correct start date."
      );
      return; // Stop execution if the dates do not match
    } else if (startDate < minDateString) {
      alert("The start date cannot be sooner than three days from today.");
      return; // Stop execution if the dates do not match
    }

    var form = $("#add-site-three")[0]; // Get the native form element
    var formData = new FormData(form); // Create a FormData object
    console.log(formData);
    $.ajax({
      url: "/wp-json/omnivision/v1/sites/add/3", // Sites REST API endpoint
      method: "POST",
      processData: false, // Important for FormData
      contentType: false, // Important for FormData
      data: formData,
      success: function (response) {
        console.log("Site " + response.post_id);
        // Redirect to /account/account-details on success
        window.location.href =
          "/account/my-sites/?site=" + response.post_id + "&added=completed";
      },
      error: function (jqXHR) {
        console.log(jqXHR);
        // Handle error - display error message, etc.
        var errorMsg = "An error occurred.";
        if (jqXHR.responseJSON && jqXHR.responseJSON.message) {
          errorMsg = jqXHR.responseJSON.message;
        }
        alert(errorMsg);
      },
    });
  });
});

document.addEventListener(
  "wpcf7mailsent",
  function (event) {
    location = "/contact-form-confirmation/";
  },
  false
);

jQuery(document).ready(function ($) {
  // Find each input with the 'required' attribute
  $("input[required], textarea[required], select[required]").each(function () {
    // Find the associated label using the 'for' attribute matching the input's id
    var label = $('label[for="' + $(this).attr("id") + '"]');
    // Append a red asterisk to the label text
    label.append('<span style="color:red;margin: 0 -10px;"> *</span>');
  });
});


$(document).ready(function () {
  // Listen for any changes on both email input fields
  $('#invoice_email, #confirm-invoice_email').on('input', function () {

    // Get the value from the email input
    var email = $('#invoice_email').val();

    // Get the value from the confirm email input
    var confirmEmail = $('#confirm-invoice_email').val();

    // Select the confirm email input and potential error message
    var $confirmInput = $('#confirm-invoice_email');
    var $errorMessage = $('#email-error-message');

    // Check if both email addresses are not empty and compare them
    if (email !== '' && confirmEmail !== '' && email !== confirmEmail) {
      // If emails do not match, change input border and background, and show error message
      $confirmInput.css({
        'border': '1px solid red',
        'background': 'pink'
      });

      // If the error message does not exist, create it
      if ($errorMessage.length === 0) {
        $confirmInput.after('<div id="email-error-message" style="color: red;">Emails do not match.</div>');
      }
    } else {
      // If emails match or one of the fields is empty, revert styles and remove error message
      $confirmInput.removeAttr('style');
      $errorMessage.remove();
    }
  });
});

$(document).ready(function () {
  // Listen for any changes on the email and confirm email input fields
  $('#email, #confirm-email').on('input', function () {

    // Get the value from the email input
    var email = $('#email').val();

    // Get the value from the confirm email input
    var confirmEmail = $('#confirm-email').val();

    // Select the confirm email input and potential error message
    var $confirmInput = $('#confirm-email');
    var $errorMessage = $('#email-error-message');

    // Check if both email addresses are not empty and compare them
    if (email !== '' && confirmEmail !== '' && email !== confirmEmail) {
      // If emails do not match, change input border and background, and show error message
      $confirmInput.css({
        'border': '1px solid red',
        'background': 'pink'
      });

      // If the error message does not exist, create it
      if ($errorMessage.length === 0) {
        $confirmInput.after('<div id="email-error-message" style="color: red; margin-top: 5px;">Emails do not match.</div>');
      }
    } else {
      // If emails match or one of the fields is empty, revert styles and remove error message
      $confirmInput.removeAttr('style');
      $errorMessage.remove();
    }
  });
});

$(document).ready(function () {
  function populateTimeDropdown(dropdown) {
    for (var hour = 0; hour < 24; hour++) {
      for (var minute = 0; minute < 60; minute += 15) {
        var time =
          (hour < 10 ? "0" : "") +
          hour +
          ":" +
          (minute < 10 ? "0" : "") +
          minute;
        dropdown.append(
          $("<option>", {
            value: time,
            text: time,
          })
        );
      }
    }
    // Add the 24:00 (or 00:00) option
    dropdown.append(
      $("<option>", {
        value: "24:00",
        text: "24:00",
      })
    );
  }

  // Common function to remove dynamically added elements
  function removeDynamicElements() {
    $('#removemelater, #single-time-input, .arming-lables, .weekday_label, .removeMelater').remove();
    $(".arming-times .d-flex-main").show(); // Show all hidden elements
  }

  // Listen for changes on the '#same-weekdays' checkbox
  $("#same-weekdays").on("change", function () {
    removeDynamicElements(); // Remove existing dynamic elements

    // If '#same-weekdays' is checked
    if ($(this).is(":checked")) {
      // Uncheck '#same-every-day' and handle the UI changes
      $('#same-every-day').prop('checked', false);
      $(".arming-times .d-flex-main:lt(6)").hide(); // Hide weekdays

      var weekdaysHtml = `
        <div id="removemelater" class="removeMelater">
          <div class="arming-lables d-flex" style="margin-bottom:10px;margin-left: 130px;">
            <small style="min-width: 163px;">Disarm</small>
            <small>Arm</small>
          </div>
          <div class="mb-1 d-flex-main">
            <label class="weekday_label" style="max-width: 99px;">Weekdays Schedule:</label>
            <div id="weekdays-time-input" class="mb-1 d-flex-main">
              <div class="d-flex">
                <select style="min-width:165px" id="weekdays-disarming-time" class="form-control"></select>
                <select style="min-width:165px" id="weekdays-arming-time" class="form-control"></select>
              </div>
            </div>
          </div>
        </div>`;
      $(".arming-times").prepend(weekdaysHtml); // Prepend the new HTML structure

      // Populate the dropdowns
      populateTimeDropdown($("#weekdays-disarming-time"));
      populateTimeDropdown($("#weekdays-arming-time"));
    }
  });

  // Listen for changes on the '#same-every-day' checkbox
  $("#same-every-day").on("change", function () {
    removeDynamicElements(); // Remove existing dynamic elements

    // If '#same-every-day' is checked
    if ($(this).is(":checked")) {
      // Uncheck '#same-weekdays' and handle the UI changes
      $('#same-weekdays').prop('checked', false);
      $(".arming-times .d-flex-main").hide(); // Hide all day inputs

      var everyDayHtml = `
        <div id="removemelater" class="arming-lables d-flex" style="margin-bottom:10px">
          <small style="min-width: 163px;">Disarm</small>
          <small>Arm</small>
        </div>
        <div id="single-time-input" class="mb-1 d-flex-main">
          <div class="d-flex">
            <select style="min-width:165px" id="single-disarming-time" class="form-control"></select>
            <select style="min-width:165px" id="single-arming-time" class="form-control"></select>
          </div>
        </div>`;
      $(".arming-times").prepend(everyDayHtml); // Prepend the new HTML structure

      // Populate the dropdowns
      populateTimeDropdown($("#single-disarming-time"));
      populateTimeDropdown($("#single-arming-time"));
    }
  });


});


/**
 * Intruder monitoring.
 * 
 */

// on page load
$(document).ready(async function () {
  // Get the intruder monitoring container
  const $intruderMonitoringContainer = $('.intruder-monitoring-fields');
  const $intruderMonitoringManufacturer = $('select#intruder_monitoring_manufacturer');
  const $intruderMonitoringType = $('select#intruder_monitoring_type');
  const $intruderMonitoringProducts = $('select#intruder_monitoring_product_range');
  const $intruderMonitoringCommunication = $('select#intruder_monitoring_communication');
  const $intruderMonitoringStandard = $('select#intruder_monitoring_standard_grade');
  var $intruderMonitoringActive = $('input#intruder_monitoring_active');

  const intruderMonitoringData = await loadIntruderMonitoringData();

  // Check if the container exists
  if ($intruderMonitoringContainer.length) {

    // Check the initial state of the checkbox
    intruderMonitoringActiveChange();

    // Attach an event listener to the checkbox
    $intruderMonitoringActive.on('change', intruderMonitoringActiveChange);

    // Attach an event listener to the manufacturer select
    $intruderMonitoringManufacturer.on('change', loadIntruderMonitoringFields);

    $intruderMonitoringType.on('change', loadProducts);
    $intruderMonitoringProducts.on('change', loadCommunication);
    $intruderMonitoringCommunication.on('change', loadStandardGrade);

    $intruderMonitoringProducts.on('change', loadCommunication);
    $intruderMonitoringCommunication.on('change', loadStandardGrade);

    $intruderMonitoringCommunication.on('change', loadStandardGrade);

    // if select#intruder_monitoring_manufacturer has a value
    if ($intruderMonitoringManufacturer.val()) {
      loadIntruderMonitoringFields();
    }

    

  }

  

  function loadIntruderMonitoringFields() {
    console.log('Loading all fields');
    loadTypes();
    loadProducts();
    loadCommunication();
    loadStandardGrade();
  }

  /**
   * Retrieves the current manufacturer from the intruderMonitoringData array.
   * @returns {Object} The manufacturer object that matches the selected manufacturer.
   */
  function getCurrentManufacturer() {
    return intruderMonitoringData.find(manufacturer => {
      return manufacturer.manufacturer === $intruderMonitoringManufacturer.find(':selected').text();
    });
  } 

  /**
   * Retrieves the types of the current manufacturer.
   * @returns {string} The types of the current manufacturer.
   */
  function getTypes() {
    const manufacturer = getCurrentManufacturer();
    return manufacturer.type;

  }

  /**
   * Loads the types for the intruder monitoring.
   */
  function loadTypes() {
    var types = getTypes();
    loadSelectFieldOptions($intruderMonitoringType, types, "type");

  }

  /**
   * Retrieves the current type based on the selected manufacturer and intruder monitoring type.
   * @returns {Object} The current type object.
   */
  function getCurrentType() {
    const manufacturer = getCurrentManufacturer();
    const type = manufacturer.type.find(type => {
      return type.type === $intruderMonitoringType.find(':selected').text();
    });

    return type;
  }

  /**
   * Retrieves the product range based on the current type.
   * @returns {Array} The product range.
   */
  function getProducts() {
    const type = getCurrentType();
    return type.product_range;
  }

  /**
   * Loads the products and populates the select field options.
   */
  function loadProducts() {
    const products = getProducts();
    loadSelectFieldOptions($intruderMonitoringProducts, products, "product");
  }

  /**
   * Retrieves the current product based on the selected type and product range.
   * @returns {Object} The current product object.
   */
  function getCurrentProduct() {
    const type = getCurrentType();
    const product = type.product_range.find(product => {
      return product.product === $intruderMonitoringProducts.find(':selected').text();
    });

    return product;
  }

  /**
   * Retrieves the communication method of the current product.
   * @returns {string} The communication method of the product.
   */
  function getCommunication() {
    const product = getCurrentProduct();
    return product.communication_method;
  }

  /**
   * Loads communication options for intruder monitoring.
   */
  function loadCommunication() {
    const communication = getCommunication();
    loadSelectFieldOptions($intruderMonitoringCommunication, communication, "communication");
  }

  /**
   * Retrieves the current communication method based on the selected option in the intruder monitoring communication dropdown.
   * @returns {Object} The communication method object.
   */
  function getCurrentCommunication() {
    const product = getCurrentProduct();
    const communication = product.communication_method.find(communication => {
      return communication.communication === $intruderMonitoringCommunication.find(':selected').text();
    });

    return communication;
  }

  /**
   * Retrieves the standard grade from the current communication.
   * @returns {number} The standard grade.
   */
  function getStandardGrade() {
    const communication = getCurrentCommunication();
    return communication.standard_grade;
  }

  /**
   * Loads the standard grade for intruder monitoring.
   */
  function loadStandardGrade() {
    const standardGrade = getStandardGrade();
    loadSelectFieldOptions($intruderMonitoringStandard, standardGrade, "standard_grade");
  }

  /**
   * Retrieves the price based on the selected intruder monitoring data
   * @returns {number|string} The price of the selected standard grade, or 'N/A' if not found.
   */
  function getPrice() {
    const communication = getCurrentCommunication();
    const standardGrade = communication.standard_grade.find(grade => {
      return grade.standard_grade === $intruderMonitoringStandard.find(':selected').text();
    });
    return standardGrade ? standardGrade.price : 'N/A';
  }

  /**
   * Load options into a select field.
   *
   * @param {jQuery} selectField - The select field element.
   * @param {Array} options - The array of options to be loaded.
   * @param {string} key - The key used to retrieve the option value.
   * @returns {void}
   */
  function loadSelectFieldOptions(selectField, options, key) {
    // Clear the select field
    selectField.empty();

    // Add the options to the select field
    options.forEach(option => {
      // Sanitize the option value
      const cleanOption = option[key].replace(/[^a-z0-9]/gi, '_').toLowerCase();
      selectField.append(`<option value="${option[key]}">${option[key]}</option>`);
    });

    // Trigger the change event to update the select field
    selectField.trigger('change');

    console.log(getPrice());
  }
  
  /**
   * Fetches intruder monitoring data from the server.
   * @returns {Promise<Object>} A promise that resolves to the fetched data.
   */
  async function loadIntruderMonitoringData() {
    return fetch('/wp-json/acf/v3/options/intruder_monitoring_data')
      .then(response => response.json())
      .then(data => {
        // Return the data for further processing
        return data;
      })
      .catch(error => console.error('Error fetching data:', error));
  }


  /**
   * Function to handle the change event of the intruder monitoring active checkbox.
   * If the checkbox is checked, it calls the openIntruderMonitoring function.
   * If the checkbox is unchecked, it calls the closeIntruderMonitoring function and logs a message to the console.
   */
  function intruderMonitoringActiveChange() {
    
    if ($intruderMonitoringActive.is(':checked')) {
      openIntruderMonitoring();
    } else {
      closeIntruderMonitoring();
      console.log('not open')
    }
  }


  /**
   * Opens the intruder monitoring container.
   */
  function openIntruderMonitoring() {
    // Show the intruder monitoring container
    $intruderMonitoringContainer.show();
  }

  /**
   * Closes the intruder monitoring container.
   */
  function closeIntruderMonitoring() {
    // Hide the intruder monitoring container
    $intruderMonitoringContainer.hide();
  }

});